<template>
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4791 11.1683H5.92188V27.4414H10.4791V11.1683Z" fill="currentColor" />
        <path
            d="M21.3013 11.1683C18.1215 11.1683 17.5747 12.3516 17.3149 13.493V11.1683H12.7577V27.4414H17.3149V18.1425C17.3149 16.6349 18.1158 15.8177 19.5935 15.8177C21.0359 15.8177 21.8721 16.6175 21.8721 18.1425V27.4414H26.4293V19.3048C26.4293 14.6554 25.8358 11.1683 21.3013 11.1683Z"
            fill="currentColor" />
        <path
            d="M8.20048 10.0059C9.45892 10.0059 10.4791 8.96509 10.4791 7.68118C10.4791 6.39726 9.45892 5.35645 8.20048 5.35645C6.94204 5.35645 5.92188 6.39726 5.92188 7.68118C5.92188 8.96509 6.94204 10.0059 8.20048 10.0059Z"
            fill="currentColor" />
    </svg>
</template>
