export const industriesWeHelp = [
    'E-Learning',
    'Healthcare',
    'Travel',
    'SaaS platforms',
    'Real Estate',
    'E-Commerce',
    'Finance',
    'Retail',
    'Transportation',
    'Education',
];
